import React from "react"
import { PageProps, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = (props: PageProps) => (
  <Layout>
    <SEO title="Why" />
    <h1>This is not a blog. But what is it?</h1>
    <div>
      Somehow I feel I need to defend my decision. But it&apos;s quite simple: &quot;stories&quot; are the new web norm. Nothing is permanent
      (that&apos;s debatable, but it&apos;s not a point). And if I&apos;m not going to update my blog regularly there&apos;s no point in keeping feed of outdated things.
      I&apos;m going to show 3-5 most recent posts. When I&apos;m writing this note, I don&apos;t know yet what it would be.
      Older links will remain valid, but will not be shown on the main page.
      Let it be exercise on text &quot;stories&quot;.
    </div>
    <Link to="/">Go back to the andrewf.net proper view</Link>
  </Layout>
)

export default SecondPage
